import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { upload_image } from "../../../api/services";

export default function TextArea({ setValue, value }) {
  const editorRef = useRef(null);
  const KEY = process.env.REACT_APP_TINY_KEY;

  const handleImageUpload = async (blobInfo) => {
    const formData = new FormData();
    formData.append("upload_img", blobInfo.blob());

    try {
      const response = await upload_image(formData);
      if (response.status === 200 && response.data.url) {
        return response.data.url;
      }
    } catch (error) {
      console.error("Image upload error:", error);
    }
  };

  return (
    <>
      <span className="text-gray-600 text-sm">Write Blog content here...</span>
      <Editor
        onChange={() =>
          setValue((old) => ({
            ...old,
            content: editorRef.current.getContent(),
          }))
        }
        apiKey={KEY}
        onInit={(_evt, editor) => (editorRef.current = editor)}
        initialValue={value}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            "link",
            "advlist autolink lists image charmap preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
            "image",
          ],
          toolbar:
            "undo redo | bold italic underline | link unlink | formatselect | " +
            "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | " +
            "image code preview",
          image_uploadtab: true,
          automatic_uploads: true,
          images_upload_handler: handleImageUpload,
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </>
  );
}
